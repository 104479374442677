import React from 'react'
import {
    Layout,
    SEO,
    FullHeightWrapper,
    Card,
    Question,
    NavLink,
    Hint
  } from '../components/elements';
import styled from '@emotion/styled';
import {Link} from 'gatsby';

const SubTitle = styled.div`
  ${tw`text-sm italic`}
`
const Produits = styled.div`
  ${tw`text-sm italic`}
`
const StyledCard = styled(Card)`
  ${tw`pt-3`}
`

const Category = ({pageContext}) => {
    const {currentSecteur, currentDistrict, secteurSlug, districtSlug, actors} = pageContext;

    const renderProducts = (productsArray) => {
      const products = productsArray.join(', ')
      if(products.length > 100)
        return products.substring(0, productsArray[0].length + productsArray[1].length + 4) + "..."
      else
        return products
    }

    return(
      <FullHeightWrapper>
        <SEO
          title={currentSecteur + ' - ' + currentDistrict}
          description={`Liste des acteurs ${currentSecteur} équitables de la région ${currentDistrict}`}
          keywords={[`entreprises`, `équitable`, `bio`, `étique`, currentSecteur, currentDistrict]}
        />
        <NavLink to={`/${secteurSlug}`}>Retour</NavLink>
        <NavLink to={`/`} content="Acceuil" right noArrow/>
        <Question>
          Les acteurs de la région
          <Hint>Cliquez sur un acteur pour en savoir plus</Hint>

        </Question>
          {
            actors.map((actor, i) => {
              return(
                <StyledCard key={i}>
                  <Link to={`/${actor.slug}`} state={{secteurSlug: secteurSlug, districtSlug: districtSlug}}>
                    <b><i>{actor.nom}</i></b>
                    <SubTitle>{actor.npaLocalite}</SubTitle>
                    <Produits>{actor.produits && renderProducts(actor.produits)}</Produits>
                  </Link>
                </StyledCard>
              )
            })
          }
      </FullHeightWrapper>
    )
}

export default Category